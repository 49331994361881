<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { KeyOutlined, UserOutlined } from '@ant-design/icons-vue'
import type { FormInstance } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import type { RegisterRequest } from '../types/user.ts'

const router = useRouter()

const formRef = ref<FormInstance | null>(null)

const form = ref<RegisterRequest>({
  username: '',
  password: ''
})

const {
  isFetching,
  execute
} = useMyFetch('/api/register', {
  immediate: false
}).post(form)

const handleSubmit = () => {
  formRef.value?.validate().then(() => {
    execute(true).then(() => {
      message.success('注册成功')
      router.push('/login')
    }).catch(() => {
      message.error('注册失败')
    })
  })
}
</script>

<template>
  <layout-auth
    title="注册"
    :actions="[
      {
        label: '已有账号',
        to: '/login'
      }
    ]"
    :loading="isFetching"
    @submit="handleSubmit"
  >
    <a-form
      ref="formRef"
      :model="form"
      layout="vertical"
    >
      <a-form-item
        name="username"
        label="用户名"
        :rules="[
          { required: true, message: '请输入用户名' },
          { regex: /^[ -~]+$/, message: '用户名仅支持英文字符' }
        ]"
      >
        <a-input
          v-model:value="form.username"
          placeholder="请输入用户名"
          :maxlength="32"
          autocomplete="username"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <user-outlined />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item
        name="password"
        label="密码"
        :rules="[{ required: true, message: '请输入密码' }]"
      >
        <a-input-password
          v-model:value="form.password"
          placeholder="请设置密码"
          autocomplete="new-password"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <key-outlined />
          </template>
        </a-input-password>
      </a-form-item>
    </a-form>
  </layout-auth>
</template>

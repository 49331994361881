<script setup lang="ts">
import { useRouter } from 'vue-router'

import AppFooter from '../components/AppFooter.vue'

const router = useRouter()

defineProps<{
  title?: string,
  actions?: {
    label: string,
    to: string
  }[],
  loading?: boolean
}>()

defineEmits([
  'submit'
])
</script>

<template>
  <a-layout class="h-screen overflow-hidden">
    <a-layout-header class="flex justify-between items-center">
      <h1 class="inline-block text-white text-xl font-medium m-0">
        智慧医疗云
      </h1>
    </a-layout-header>
    <a-layout-content class="overflow-y-auto sm:px-layout sm:py-16">
      <div class="max-w-md w-full mx-auto bg-white py-6 sm:rounded-lg">
        <div class="px-6">
          <h2
            v-if="title"
            class="text-lg font-medium"
            v-text="title"
          />
        </div>
        <a-divider />
        <div class="px-6">
          <slot />
        </div>
        <a-divider />
        <div class="flex justify-between gap-4 px-6">
          <a-button
            type="primary"
            :loading="loading"
            @click="$emit('submit')"
          >
            {{ title ?? '提交' }}
          </a-button>
          <span class="flex-1" />
          <a-button
            v-for="action of actions"
            type="link"
            v-text="action.label"
            @click="router.push(action.to)"
          />
        </div>
      </div>
    </a-layout-content>
    <app-footer />
  </a-layout>
</template>

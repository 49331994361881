<script setup lang="ts">
import { SmileOutlined } from '@ant-design/icons-vue'

import { useStore } from '../store.ts'

const store = useStore()
</script>

<template>
  <a-alert
    type="success"
    message="欢迎使用智慧医疗云！"
    :description="store.user ? '您当前角色为：' + store.user.user.roles.map(r => r.name).join('、') : '请先登录或注册'"
    show-icon
  >
    <template #icon>
      <smile-outlined />
    </template>
  </a-alert>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons-vue'
import type { ItemType } from 'ant-design-vue'

import { useStore } from '../store.ts'
import AppFooter from '../components/AppFooter.vue'

const store = useStore()

const route = useRoute()

const router = useRouter()

const selectedKeys = ref<string[]>([])
const openKeys = ref<string[]>(['admin', 'hospital', 'outpatient', 'hospitalization', 'patient'])

const handleMenuClick = (item: ItemType) => {
  if (item?.key) {
    router.push(`/portal/${item.key}`)
  }
}

const handleLogout = () => {
  store.clearToken()
  router.push('/login')
}

const handleBack = () => {
  router.back()
}

watch(() => route.path, (v) => {
  const parts = v?.split('/').filter((part) => {
    return part && part !== 'portal'
  })
  if (parts && parts.length) {
    selectedKeys.value = [parts.join('/')]
  } else {
    selectedKeys.value = []
  }
}, {
  immediate: true
})
</script>

<template>
  <a-layout class="h-screen overflow-hidden">
    <a-layout-header class="flex items-center gap-4">
      <router-link
        to="/portal"
        class="inline-block !text-white text-xl font-medium m-0"
      >
        智慧医疗云
      </router-link>
      <a-menu
        theme="dark"
        mode="horizontal"
        :selectable="false"
      >
        <a-menu-item @click="router.push('/portal/about')">
          说明
        </a-menu-item>
      </a-menu>
      <span class="flex-1" />
      <a-menu
        theme="dark"
        mode="horizontal"
        :selectable="false"
      >
        <a-menu-item
          v-if="!store.user"
          @click="router.push('/login')"
        >
          登录
        </a-menu-item>
        <a-menu-item
          v-if="!store.user"
          @click="router.push('/register')"
        >
          注册
        </a-menu-item>
        <a-sub-menu
          v-if="store.user"
          theme="light"
        >
          <template #title>
            <span class="flex items-center gap-2">
              <a-avatar class="!bg-white/25">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
              {{ store.user.username }}
            </span>
          </template>
          <a-menu-item @click="router.push('/update-questions')">
            <template #icon>
              <edit-outlined />
            </template>
            修改密保问题
          </a-menu-item>
          <a-menu-item @click="router.push('/reset-password')">
            <template #icon>
              <edit-outlined />
            </template>
            修改密码
          </a-menu-item>
          <a-menu-item @click="handleLogout">
            <template #icon>
              <logout-outlined />
            </template>
            退出登录
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-header>
    <a-layout>
      <a-layout-sider width="200" class="!bg-white overflow-y-auto">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          v-model:openKeys="openKeys"
          mode="inline"
          class="h-full border-r-0"
          @click="handleMenuClick"
        >
          <a-sub-menu
            v-if="store.hasRole('admin')"
            key="admin"
          >
            <template #title>
              <span>
                <setting-outlined />
                系统管理
              </span>
            </template>
            <a-menu-item key="admin/users">用户管理</a-menu-item>
            <a-menu-item key="admin/hospitals">医院管理</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="store.hasRole('hospital_admin')"
            key="hospital"
          >
            <template #title>
              <span>
                <setting-outlined />
                医院管理
              </span>
            </template>
            <a-menu-item key="hospital">基本信息</a-menu-item>
            <a-menu-item key="hospital/campuses">院区管理</a-menu-item>
            <a-menu-item key="hospital/departments">科室管理</a-menu-item>
            <a-menu-item key="hospital/doctors">医生管理</a-menu-item>
            <a-menu-item key="hospital/nurses">护士管理</a-menu-item>
            <a-menu-item key="hospital/outpatient-times">出诊时段管理</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="store.hasRole(['hospital_admin', 'doctor'])"
            key="outpatient"
          >
            <template #title>
              <span>
                <setting-outlined />
                门诊管理
              </span>
            </template>
            <a-menu-item
              v-if="store.hasRole('doctor')"
              key="outpatient/admission"
            >
              接诊
            </a-menu-item>
            <a-menu-item key="outpatient/records">接诊记录</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="store.hasRole(['hospital_admin', 'doctor', 'nurse'])"
            key="hospitalization"
          >
            <template #title>
              <span>
                <setting-outlined />
                住院管理
              </span>
            </template>
            <a-menu-item key="hospitalization/beds">床位管理</a-menu-item>
            <a-menu-item key="hospitalization/records">住院记录</a-menu-item>
          </a-sub-menu>
          <a-sub-menu
            v-if="!store.user || store.hasRole('patient')"
            key="patient"
          >
            <template #title>
              <span>
                <user-outlined />
                患者服务
              </span>
            </template>
            <a-menu-item key="patient/patients">就诊人信息</a-menu-item>
            <a-menu-item key="patient/registrations">在线挂号</a-menu-item>
            <a-menu-item key="patient/records">就诊记录</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout class="overflow-y-auto">
        <a-layout-content class="p-6">
          <a-page-header
            v-if="route.meta.title"
            :title="route.meta.title"
            :sub-title="route.meta.subTitle"
            class="!px-0 !pt-0"
            @back="handleBack"
          />
          <router-view />
        </a-layout-content>
        <app-footer />
      </a-layout>
    </a-layout>
  </a-layout>
</template>

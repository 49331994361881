import { createApp } from 'vue'
import { createPinia } from 'pinia'
import {
  Alert,
  AutoComplete,
  Avatar,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  PageHeader,
  Table,
  Tag,
  Select,
  Space,
  StyleProvider
} from 'ant-design-vue'

import router from './router'
import App from './App.vue'

import 'ant-design-vue/dist/reset.css'
import './assets/css/tailwind.css'

const app = createApp(App)

// register components
app.use(Alert)
app.use(AutoComplete)
app.use(Avatar)
app.use(Button)
app.use(Card)
app.use(Divider)
app.use(Form)
app.use(Input)
app.use(Layout)
app.use(Menu)
app.use(Modal)
app.use(PageHeader)
app.use(Table)
app.use(Tag)
app.use(Select)
app.use(Space)
app.use(StyleProvider)

// register libraries
app.use(createPinia())
app.use(router)

app.mount('#app')

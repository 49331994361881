<script setup lang="ts">
import { computed, ref } from 'vue'
import type { FormInstance } from 'ant-design-vue'
import { message, Modal } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'

import { myFetchToPromise, useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import InputUsername from '../components/InputUsername.vue'
import SelectDepartment from '../components/SelectDepartment.vue'
import SelectHospital from '../components/SelectHospital.vue'
import type { Nurse, UpdateNurseRequest } from '../types/hospital.ts'

const store = useStore()

const hospitalId = computed<number>(() => {
  return store.hospital?.id ?? 0
})

const {
  execute,
  data,
  isFetching
} = useMyFetch(() => {
  return `/api/hospitals/${hospitalId.value}/nurses`
}, {
  refetch: true
}).json<Nurse[]>()

const modalEditOpen = ref(false)

const editingId = ref<number | null>(null)

const formEditRef = ref<FormInstance | null>(null)

const formEdit = ref<Required<UpdateNurseRequest>>({
  department: 0,
  username: '',
  name: '',
  gender: '',
  title: ''
})

const handleCreate = () => {
  modalEditOpen.value = true
  editingId.value = null
  formEdit.value = {
    department: 0,
    username: '',
    name: '',
    gender: '',
    title: ''
  }
}

const handleEdit = (id: number) => {
  editingId.value = id
  useMyFetch(`/api/nurses/${id}`).json<Nurse>().then(({ data }) => {
    if (data.value) {
      formEdit.value = {
        department: data.value.department.id,
        username: data.value.user?.username ?? '',
        name: data.value.name,
        gender: data.value.gender ?? '',
        title: data.value.title ?? ''
      }
      modalEditOpen.value = true
    }
  })
}

const handleSubmitEdit = async () => {
  try {
    await formEditRef.value?.validate()
    if (editingId.value === null) {
      await myFetchToPromise(useMyFetch(`/api/nurses`)
        .post(formEdit.value))
    } else {
      await myFetchToPromise(useMyFetch(`/api/nurses/${editingId.value}`)
        .put(formEdit.value))
    }
    store.fetchUser()
    await execute(true)
    message.success('保存成功')
    modalEditOpen.value = false
  } catch (error: any) {
    console.error(error)
    if (error.response?.status === 409) {
      message.error('用户名已存在')
    } else {
      message.error('保存失败')
    }
  }
}

const handleCancelEdit = () => {
  formEditRef.value?.clearValidate()
}

const handleDelete = (id: number) => {
  Modal.confirm({
    title: '注意',
    content: '确定要删除此护士吗？所有关联的资源都将被一并删除',
    cancelText: '取消',
    okText: '确定',
    onOk: async () => {
      await myFetchToPromise(useMyFetch(`/api/nurses/${id}`)
        .delete())
      store.fetchUser()
      await execute(true)
      message.success('删除成功')
    }
  })
}
</script>

<template>
  <div class="flex items-center mb-4">
    <a-button
      type="primary"
      @click="handleCreate"
    >
      <plus-outlined />
      添加护士
    </a-button>
    <span class="flex-1" />
    <select-hospital />
  </div>
  <a-table
    :columns="[
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '科室',
        dataIndex: ['department', 'name']
      },
      {
        title: '用户名',
        dataIndex: ['user', 'username']
      },
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '性别',
        dataIndex: 'gender'
      },
      {
        title: '职称',
        dataIndex: 'title'
      },
      {
        title: '专业领域',
        dataIndex: 'speciality'
      },
      {
        key: 'actions',
        align: 'right'
      }
    ]"
    row-key="id"
    :data-source="data"
    :loading="isFetching"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'actions'">
        <a-space>
          <a-button
            type="link"
            size="small"
            @click="handleEdit(record.id)"
          >
            编辑
          </a-button>
          <a-button
            type="link"
            danger
            size="small"
            @click="handleDelete(record.id)"
          >
            删除
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal
    v-model:open="modalEditOpen"
    :title="editingId ? '编辑护士信息' : '添加护士'"
    cancel-text="取消"
    ok-text="提交"
    @cancel="handleCancelEdit"
    @ok="handleSubmitEdit"
  >
    <a-form
      ref="formEditRef"
      :model="formEdit"
      layout="vertical"
    >
      <a-form-item
        label="所属科室"
        name="department"
        :rules="[{ type: 'number', required: true, min: 1, message: '请选择护士所属科室' }]"
      >
        <select-department v-model="formEdit.department" />
      </a-form-item>
      <a-form-item
        label="用户名"
        name="username"
        :rules="[{ pattern: /^[ -~]+$/, message: '用户名仅支持英文字符' }]"
        extra="请输入护士用户名，不能与其他护士相同；若该用户不存在，将被创建，初始密码与用户名相同"
      >
        <input-username v-model="formEdit.username" />
      </a-form-item>
      <a-form-item
        label="姓名"
        name="name"
        :rules="[{ required: true, message: '请输入护士姓名' }]"
      >
        <a-input
          v-model:value="formEdit.name"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="性别"
        name="gender"
      >
        <a-input
          v-model:value="formEdit.gender"
          :maxlength="80"
        />
      </a-form-item>
      <a-form-item
        label="职称"
        name="title"
      >
        <a-input
          v-model:value="formEdit.title"
          :maxlength="80"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

import { useMyFetch } from './fetch.ts'
import type { Doctor, Hospital, Nurse } from './types/hospital.ts'
import type { TokenResponse, UserDetails } from './types/user.ts'

export const useStore = defineStore('cloud', () => {

  /**
   * Token management
   */

  const token = useLocalStorage('token', '')
  const tokenExpiry = useLocalStorage('token_expiry', 0)

  const {
    data: user,
    isFetching: isFetchingUser,
    execute: fetchUser
  } = useMyFetch('/api/user', {
    immediate: false
  }).json<UserDetails>()

  const setToken = (tokenResponse: TokenResponse) => {
    token.value = tokenResponse.access_token
    tokenExpiry.value = tokenResponse.expires_in * 1000 + Date.now()
    user.value = null
  }

  const clearToken = () => {
    token.value = ''
    tokenExpiry.value = 0
    user.value = null
  }

  /**
   * User roles
   */

  const hasRole = (roles: string | string[]): boolean => {
    if (typeof roles === 'string') {
      roles = [roles]
    }

    return roles.some((role) => {
      return (user.value?.authorities ?? []).some((authority) => {
        return authority.authority == 'ROLE_' + role.toUpperCase()
      })
    })
  }

  const hospitalSelected = ref(0)

  watch(user, (v) => {
    if ((v?.user.hospitals?.length ?? 0) <= hospitalSelected.value) {
      hospitalSelected.value = 0
    }
  })

  const hospital = computed<Hospital | null>(() => {
    if (user.value?.user.hospitals?.length) {
      return user.value.user.hospitals[hospitalSelected.value]
    }
    return null
  })

  const doctor = computed<Doctor | null>(() => {
    return user.value?.user.doctor ?? null
  })

  const nurse = computed<Nurse | null>(() => {
    return user.value?.user.nurse ?? null
  })

  return {
    clearToken,
    doctor,
    fetchUser,
    isFetchingUser,
    hasRole,
    hospital,
    hospitalSelected,
    nurse,
    setToken,
    token,
    tokenExpiry,
    user
  }
})

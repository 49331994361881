<script setup lang="ts">
import { computed } from 'vue'
import type { SelectProps } from 'ant-design-vue'

import { useStore } from '../store.ts'

const store = useStore()

const options = computed<NonNullable<SelectProps['options']>>(() => {
  return store.user?.user.hospitals?.map((v, i) => ({
    value: i,
    label: v.name
  })) ?? []
})
</script>

<template>
  <a-form-item
    label="当前医院"
    class="!m-0"
  >
    <a-select
      :value="store.hospitalSelected"
      :options="options"
      placeholder="请选择医院"
      @update:value="store.hospitalSelected = $event"
    />
  </a-form-item>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { watchDebounced } from '@vueuse/core'
import { message } from 'ant-design-vue'
import { KeyOutlined, UserOutlined } from '@ant-design/icons-vue'
import type { FormInstance } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import type { ResetPasswordRequest, SecurityQuestion } from '../types/user.ts'

const store = useStore()

const router = useRouter()

const formRef = ref<FormInstance | null>(null)

const form = ref<Required<ResetPasswordRequest>>({
  username: '',
  oldPassword: '',
  answers: {},
  newPassword: ''
})

const {
  error,
  isFetching,
  execute
} = useMyFetch('/api/user/reset-password', {
  immediate: false
}).post(form)

const questions = ref<SecurityQuestion[]>([])
const questionsEmpty = ref<boolean>(false)

const handleSubmit = () => {
  formRef.value?.validate().then(() => {
    execute(true).then(() => {
      message.success('重置密码成功')
      router.push(store.user ? '/portal' : '/login')
    })
  })
}

watch(() => store.user, (v) => {
  form.value.username = v?.username ?? ''
})

watchDebounced(() => form.value.username, (v) => {
  questionsEmpty.value = false
  if (v && !store.user) {
    useMyFetch('/api/user/questions?' + (new URLSearchParams({
      username: v
    })).toString()).json<SecurityQuestion[]>().then(({ data }) => {
      questionsEmpty.value = data.value?.length === 0
      questions.value = data.value ?? []
    }, () => {
      questionsEmpty.value = true
      questions.value = []
    })
  } else {
    questions.value = []
  }
}, {
  immediate: true,
  debounce: 500
})
</script>

<template>
  <layout-auth
    title="重置密码"
    :actions="[
      {
        label: '返回',
        to: store.user ? '/portal' : '/login'
      }
    ]"
    :loading="isFetching"
    @submit="handleSubmit"
  >
    <a-form
      ref="formRef"
      :model="form"
      layout="vertical"
    >
      <a-form-item
        v-if="store.user"
        name="oldPassword"
        label="旧密码"
        :rules="[{ required: true, message: '请输入旧密码' }]"
      >
        <a-input-password
          v-model:value="form.oldPassword"
          placeholder="请输入旧密码"
          autocomplete="username"
        >
          <template #prefix>
            <key-outlined />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item
        v-else
        name="username"
        label="用户名"
        :rules="[{ required: true, message: '请输入用户名' }]"
      >
        <a-input
          v-model:value="form.username"
          placeholder="请输入用户名"
          autocomplete="username"
        >
          <template #prefix>
            <user-outlined />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item
        v-for="question of questions"
        :key="question.id"
        :name="['answers', question.id]"
        :rules="[{ required: true, message: '请输入密保问题答案' }]"
        :label="question.question"
      >
        <a-input
          v-model:value="form.answers[question.id]"
          placeholder="请输入密保问题答案"
          autocomplete="username"
        />
      </a-form-item>
      <a-form-item
        v-if="store.user || questions.length"
        name="newPassword"
        label="新密码"
        :rules="[{ required: true, message: '请输入新密码' }]"
        :validate-status="error ? 'error' : undefined"
        :help="error ? ('重置密码失败，请检查' + (store.user ? '旧密码' : '用户名或密保问题答案') + '是否正确') : undefined"
      >
        <a-input-password
          v-model:value="form.newPassword"
          placeholder="请设置密码"
          autocomplete="new-password"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <key-outlined />
          </template>
        </a-input-password>
      </a-form-item>
      <a-form-item v-else-if="form.username">
        <a-alert
          v-if="questionsEmpty"
          message="用户名不正确或此用户未设置密保问题"
          type="warning"
          show-icon
        />
        <a-alert
          v-else
          message="正在加载密保问题"
          type="info"
          show-icon
        />
      </a-form-item>
    </a-form>
  </layout-auth>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { watchDebounced } from '@vueuse/core'
import type { AutoCompleteProps } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const store = useStore()

const canAutocomplete = computed<boolean>(() => {
  return store.hasRole(['admin', 'hospital_admin'])
})

const options = ref<NonNullable<AutoCompleteProps['options']>>([])

watchDebounced(() => props.modelValue, (v) => {
  if (v && /^[ -~]+$/.test(v) && canAutocomplete) {
    const url = '/api/autocomplete/users?' + (new URLSearchParams({
      search: props.modelValue
    }).toString())
    useMyFetch(url).json<string[]>().then(({ data }) => {
      if (data.value) {
        options.value = data.value.map((username) => ({
          value: username,
          text: username
        }))
      }
    })
  } else {
    options.value = []
  }
}, {
  immediate: true,
  debounce: 200
})
</script>

<template>
  <a-auto-complete
    :value="modelValue"
    :maxlength="32"
    allow-clear
    backfill
    :options="options"
    @update:value="emit('update:modelValue', $event)"
    v-bind="$attrs"
  />
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { KeyOutlined } from '@ant-design/icons-vue'
import type { FormInstance } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import LayoutAuth from '../layouts/LayoutAuth.vue'
import type { SecurityQuestion, UpdateSecurityQuestionRequest } from '../types/user.ts'

const store = useStore()

const router = useRouter()

const formRef = ref<FormInstance | null>(null)

const form = ref<UpdateSecurityQuestionRequest>({
  password: '',
  questions: [{
    question: '',
    answer: ''
  }]
})

const handleAdd = () => {
  form.value.questions = [
    ...form.value.questions,
    {
      question: '',
      answer: ''
    }
  ]
}

const handleDelete = (i: number) => {
  form.value.questions = form.value.questions.filter((_, index) => index !== i)
}

const {
  error,
  execute,
  isFetching
} = useMyFetch('/api/user/questions', {
  immediate: false
}).post(form)

const handleSubmit = () => {
  formRef.value?.validate().then(() => {
    execute(true).then(() => {
      message.success('修改密保问题成功')
      router.push('/portal')
    })
  })
}

watch(() => store.user, () => {
  useMyFetch('/api/user/questions')
    .json<SecurityQuestion[]>()
    .then(({ data }) => {
      if (data.value?.length) {
        form.value.questions = data.value.map((question) => ({
          question: question.question,
          answer: ''
        }))
      }
    })
}, {
  immediate: true
})
</script>

<template>
  <layout-auth
    title="修改密保问题"
    :actions="[
      {
        label: '返回',
        to: '/portal'
      }
    ]"
    :loading="isFetching"
    @submit="handleSubmit"
  >
    <a-form
      ref="formRef"
      :model="form"
      layout="vertical"
    >
      <a-form-item
        name="password"
        label="密码"
        :rules="[{ required: true, message: '请输入当前密码' }]"
        :validate-status="error ? 'error' : undefined"
        :help="error ? '修改密保问题失败，请检查密码输入是否正确' : undefined"
      >
        <a-input-password
          v-model:value="form.password"
          placeholder="请验证当前密码"
          autocomplete="new-password"
          @pressEnter="handleSubmit"
        >
          <template #prefix>
            <key-outlined />
          </template>
        </a-input-password>
      </a-form-item>
      <template v-for="(question, i) in form.questions">
        <a-form-item
          :name="['questions', i, 'question']"
          :label="'问题 ' + (i + 1)"
          :rules="[{ required: true, message: '请输入密保问题' }]"
          class="!mb-2"
        >
          <a-input-group
            class="!flex"
            compact
          >
            <a-input
              v-model:value="question.question"
              :placeholder="'请输入密保问题 ' + (i + 1)"
              :maxlength="80"
              class="flex-1"
            />
            <a-button
              danger
              @click="handleDelete(i)"
            >
              删除
            </a-button>
          </a-input-group>
        </a-form-item>
        <a-form-item
          :name="['questions', i, 'answer']"
          :rules="[{ required: true, message: '请输入密保问题答案' }]"
        >
          <a-input
            v-model:value="question.answer"
            :placeholder="'请输入密保问题 ' + (i + 1) + ' 的答案'"
            :maxlength="80"
          />
        </a-form-item>
      </template>
      <a-form-item>
        <a-button
          type="dashed"
          @click="handleAdd"
        >
          添加密保问题
        </a-button>
      </a-form-item>
    </a-form>
  </layout-auth>
</template>

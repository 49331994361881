<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import type { SelectProps } from 'ant-design-vue'

import { useMyFetch } from '../fetch.ts'
import { useStore } from '../store.ts'
import type { HospitalDepartment } from '../types/hospital.ts'

const props = defineProps<{
  modelValue: number
}>()

const emit = defineEmits<{
  'update:modelValue': [value: number]
}>()

const store = useStore()

const {
  data
} = useMyFetch(() => {
  return `/api/hospitals/${store.hospital?.id ?? 0}/departments`
}, {
  refetch: true
}).json<HospitalDepartment[]>()

const options = computed<NonNullable<SelectProps['options']>>(() => {
  const result = data.value?.map((v) => ({
    label: v.name,
    value: v.id
  })) ?? []
  return result.length ? result : [{
    value: 0,
    label: '暂无科室信息'
  }]
})

watchEffect(() => {
  if (!options.value.some((o) => o.value === props.modelValue)) {
    emit('update:modelValue', options.value[0].value as number)
  }
})
</script>

<template>
  <a-select
    :value="modelValue"
    :options="options"
    placeholder="请选择科室"
    @update:value="$emit('update:modelValue', $event)"
  />
</template>

import type { UseFetchReturn } from '@vueuse/core'
import { createFetch } from '@vueuse/core'
import { message } from 'ant-design-vue'

import { useStore } from './store.ts'

export const useMyFetch = createFetch({
  combination: 'chain',
  options: {
    beforeFetch: (ctx) => {
      const store = useStore()
      if (store.token) {
        if (store.tokenExpiry < Date.now()) {
          store.clearToken()
        } else {
          ctx.options.headers = {
            ...ctx.options.headers,
            Authorization: `Bearer ${store.token}`,
          }
        }
      }
    },
    onFetchError: (ctx) => {
      if (!ctx.response || ctx.response.status >= 502) {
        message.error('无法连接到服务器')
      } else if (ctx.response.status === 500) {
        message.error('服务器发生错误')
      } else if (ctx.response.status === 401 && !ctx.response.url.includes('token')) {
        message.warn('登录状态失效，请重新登录')
        useStore().clearToken()
      }
      return ctx
    }
  }
})

export const myFetchToPromise = <T>(instance: PromiseLike<UseFetchReturn<T>>): Promise<T | null> => {
  return new Promise((resolve, reject) => {
    instance.then((result) => {
      if (result.error.value) {
        reject({
          error: result.error.value,
          response: result.response.value
        })
      } else {
        resolve(result.data.value)
      }
    }, (e) => {
      reject({
        error: e,
        response: null
      })
    })
  })
}
